import './App.scss';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import HomePage from './components/homepage';
import Nav from './components/nav';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <><HomePage /></>
      },
    ],
  },

]);

export function Root() {
  return (
    <>
      <Nav />
      <Outlet />
    </>
  );
}


function App() {

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
