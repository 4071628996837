import './homepage.scss';
import Newsletter from './newsletter';
import Footer from './footer';

const HomePage = () => {
  return (
    <div className="main">
      <Newsletter />
      <Footer />
    </div>
  );
};

export default HomePage;
