import React, {useState} from 'react';
import firebase from 'firebase/app';
import {ref, push} from "firebase/database";
import {database} from '../lib/firebase';
import homepageData from '../data/homepage.json'
import './newsletter.scss'

const emailsRef = ref(database, 'subscriptionEmails');

function Newsletter() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Store the email in Firebase
    push(emailsRef, {email});

    // Clear the input field
    setEmail('');
    setSubmitted(true);
  };

  return (
    <div className="newsletter">
      <div className="newsletter__inner">

        {submitted && (
          <div>
            <p>Thanks for signing up!</p>
            <p>We'll keep you updated.</p>
          </div>
        )}

        {!submitted && (
          <>

            <div className="newsletter__asset">
              <img src={homepageData.joinTheSquadImage} alt={homepageData.joinTheSquadImageAlt}
                className="newsletter__image"
              />
            </div>
            <div className="newsletter__content">
              <h2>Join the Squad</h2>
              <p>Get coupons, freebies + news!</p>
              <form onSubmit={handleSubmit} className="newsletter_form">
                <input
                  className="newsletter_input"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button
                  className="newsletter_submit"
                  type="submit">Submit</button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Newsletter;
