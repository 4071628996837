import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// https://console.firebase.google.com/u/0/project/marphy-2ef95/settings/general/web:NzU2Y2JiNmItMTZiNi00NzhmLWE4OWItNTg5YWEwZjYwM2Q3
export const firebaseConfig = {
  apiKey: "AIzaSyDwRGQNO1dzvC7omN1zEGN5whpgDAh__kc",
  authDomain: "speechsquad-57e82.firebaseapp.com",
  projectId: "speechsquad-57e82",
  storageBucket: "speechsquad-57e82.appspot.com",
  messagingSenderId: "400377799848",
  appId: "1:400377799848:web:139a08156dc689b1d78e9d",
  measurementId: "G-C49KQCZW55",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
