
import './footer.scss';
import homepageData from '../data/homepage.json'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__logo">
          <img src={homepageData.zokksLogo} alt={homepageData.zokksLogoAlt}
          />
        </div>


        <div className="footer__copyright">
          <img src={homepageData.logo} alt={homepageData.logoAlt}
            className="nav__logo"
          />
          <div className="footer__disclaimer">
            <div dangerouslySetInnerHTML={{__html: homepageData.copyright}}></div>
            <div dangerouslySetInnerHTML={{__html: homepageData.trademark}}></div>
          </div>

        </div>


      </div>
    </div>
  );
};

export default Footer;
