
import './nav.scss';
import homepageData from '../data/homepage.json'

const Nav = () => {
  return (
    <div className="nav">
      <img src={homepageData.logo} alt={homepageData.logoAlt}
        className="nav__logo"
      />
    </div>
  )
};
export default Nav;
